/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import ReactPlayer from 'react-player/youtube';

import { graphql, PageProps } from 'gatsby';
import theme from '../theme';
import DetailPageContainer from '../components/DetailPageContainer';

const videoOuterStyle = css`
  position: relative;
  width: 100%;
  padding-bottom: 67%;
`;

const videoInnerStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const listStyle = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 40px;
`;

const listItemStyle = css`
  display: flex;
  align-items: center;
  padding: 16px 16px;
`;

const listItemActiveStyle = css`
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.common.white};
`;

const videoDataStyle = css`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

const titleStyle = css`
  font-weight: bold;
`;

const numberStyle = css`
  font-family: Lust Display, serif;
  font-size: 1.6em;
  display: flex;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

interface Video {
  song_title: string,
  artist: string,
  url: string,
  description?: string,
}

type Props = {
  title: string
  videos: Video[]
};

export function MediaPageTemplate(props: Props): React.ReactElement {
  const { title, videos } = props;

  const [video, setVideo] = React.useState<Video>(videos[0]);

  function renderListItem(v: Video, idx: number) {
    return (
      <button
        type="button"
        key={v.song_title}
        css={[listItemStyle, v.song_title === video.song_title && listItemActiveStyle]}
        onClick={() => setVideo(v)}
      >
        <div css={numberStyle}>{idx + 1}</div>
        <div css={videoDataStyle}>
          <span css={titleStyle}>{v.song_title}</span>
          <span>{v.artist}</span>
          {v.description && (
            <span>{v.description}</span>
          )}
        </div>
      </button>
    );
  }

  return (
    <DetailPageContainer title={title}>
      <div css={videoOuterStyle}>
        <div css={videoInnerStyle}>
          <ReactPlayer url={video.url} conrols width="100%" height="100%" />
        </div>
      </div>
      <div css={listStyle}>
        {videos.map(renderListItem)}
      </div>
    </DetailPageContainer>
  );
}

type MediaQueryData = {
  prismicMedia: {
    data: {
      title: string
      body: Array<{
        items: Video[]
      }>
    }
  }
};

function MediaPage(props: PageProps<MediaQueryData>): React.ReactElement {
  const { data } = props;
  const mediaData = data.prismicMedia.data;

  return (
    <MediaPageTemplate title={mediaData.title} videos={mediaData.body[0].items} />
  );
}

export const query = graphql`
query MediaQuery {
  prismicMedia {
    data {
      title
      body {
        ... on PrismicMediaDataBodyVideo {
          items {
            artist
            song_title
            url
          }
        }
      }
    }
  }
}
`;

export default MediaPage;
