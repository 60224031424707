/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import Text from './Text';
import MainContainer from './MainContainer';

const rootStyle = css`
  min-height: 100%;
  padding-top: 64px;
`;

const titleStyle = css`
  margin-bottom: 40px;
`;

const containerStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
  width: 100%;
`;

type Props = {
  title: string,
  children: React.ReactNode,
  className?: string,
};

function DetailPageContainer(props: Props): React.ReactElement {
  const { title, children, className } = props;

  return (
    <MainContainer>
      <div css={rootStyle}>
        <div className={className} css={containerStyle}>
          <Text css={titleStyle} variant="h1">
            {title}
          </Text>
          <div>{children}</div>
        </div>
      </div>
    </MainContainer>
  );
}

DetailPageContainer.defaultProps = {
  className: undefined,
};

export default DetailPageContainer;
